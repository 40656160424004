import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client} from "../../sanity";

// Helper function to fetch data without delay

const fetchData = async (query) => {
  return client.fetch(query);
};

// Fetch thunks
const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const fetchGallery = createAsyncThunk(
  "gallery/fetchGallery",
  async () => {
    try {
      const response = await fetchData(`
        *[_type == "event"][0]{
          images[] {
            _key,
            category,
            "imageUrl": image.asset->url
          },
          videoFiles[] {
            _key,
            category,
            "videoUrl": file.asset->url
          }
        }
      `);
      
      // Extract images and video files
      const { images, videoFiles } = response;

      // Extract unique categories using Set to ensure uniqueness
      const uniqueCategories = new Set();

      // Normalize categories to title case and add to the set
      images.forEach((image) => {
        const category = toTitleCase(image.category);
        uniqueCategories.add(category.trim());
      });

      videoFiles.forEach((video) => {
        const category = toTitleCase(video.category);
        uniqueCategories.add(category);
      });

      // Convert uniqueCategories Set to array
      const uniqueCategoriesArray = Array.from(uniqueCategories);
      
      return { images, videoFiles, uniqueCategoriesArray };
    } catch (error) {
      console.error("Error fetching gallery:", error);
      throw error;
    }
  }
);

export const fetchAbout = createAsyncThunk("about/fetchAbout", async () => {
  try {
    const response = await fetchData(`
            *[_type == 'about'][0] {
              picture {
                "imageUrl": asset-> { url }
              },
              aboutYou
            }
        `);
    return response;
  } catch (error) {
    console.error("Error fetching about:", error);
    throw error;
  }
});

export const fetchBlog = createAsyncThunk("blog/fetchBlog", async () => {
  try {
    const response = await fetchData(`
            *[_type == 'blog'] {
              title,
              description,
              youtubeLink,
              blogImage { "imageUrl": asset-> { url } },
              _createdAt
            }
        `);
    return response;
  } catch (error) {
    console.error("Error fetching blog:", error);
    throw error;
  }
});

export const fetchBannerVideo = createAsyncThunk(
  "banner/fetchBannerVideo",
  async () => {
    try {
      const response = await fetchData(`
            *[_type == "banner"][0] {
              "videoUrl": videoFile.asset->url
            }
        `);
       
      return response;
    } catch (error) {
      console.error("Error fetching banner video:", error);
      throw error;
    }
  }
);

export const fetchBrandLogo = createAsyncThunk(
  "brand/fetchBrandLogo",
  async () => {
    try {
      const response = await fetchData(`
            *[_type == "clientLogos"][0] {
              images[] {
                "imageUrl": asset-> { url }
              }
            }
        `);
      return response;
    } catch (error) {
      console.error("Error fetching brand logos:", error);
      throw error;
    }
  }
);

// Slices
const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGallery.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGallery.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchGallery.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const aboutSlice = createSlice({
  name: "about",
  initialState: {
    data: {},
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAbout.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAbout.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchAbout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchBlog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerVideo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBannerVideo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchBannerVideo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const brandSlice = createSlice({
  name: "brands",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrandLogo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBrandLogo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.images;
      })
      .addCase(fetchBrandLogo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const galleryReducer = gallerySlice.reducer;
export const aboutReducer = aboutSlice.reducer;
export const blogReducer = blogSlice.reducer;
export const bannerReducer = bannerSlice.reducer;
export const brandReducer = brandSlice.reducer;
