import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Masonry from "react-masonry-css";
import { FaPlay, FaPause, FaExpand } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

const CategoryData = React.memo(() => {
  const location = useLocation();
  const { gallery, selectedCategory } = location.state || {};
  function toUpperCase(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  document.title = `${toUpperCase(selectedCategory)} | Anil Attry`;
  const mediaRefs = useRef([]);
  const timeoutRefs = useRef([]);
  const observer = useRef(null);
  const [isPlayingKey, setIsPlayingKey] = useState(null);
  const [selectedItemKey, setSelectedItemKey] = useState(null);

  const playMedia = useCallback(
    debounce((key) => {
      clearTimeout(timeoutRefs.current[key]);
      timeoutRefs.current[key] = setTimeout(() => {
        const mediaElement = mediaRefs.current[key];
        if (mediaElement && typeof mediaElement.play === "function") {
          mediaElement
            .play()
            .then(() => setIsPlayingKey(key))
            .catch((error) => {
              console.error("Play error:", error);
            });
        }
      }, 100);
    }, 100),
    []
  );

  const pauseMedia = useCallback(
    debounce((key) => {
      clearTimeout(timeoutRefs.current[key]);
      timeoutRefs.current[key] = setTimeout(() => {
        const mediaElement = mediaRefs.current[key];
        if (mediaElement && typeof mediaElement.pause === "function") {
          mediaElement.pause();
          setIsPlayingKey(null);
        }
      }, 100);
    }, 100),
    []
  );

  const togglePlayPause = (key) => {
    const mediaElement = mediaRefs.current[key];
    if (!mediaElement) return;

    if (mediaElement.paused) {
      mediaElement
        .play()
        .then(() => setIsPlayingKey(key))
        .catch((error) => {
          console.error("Play error:", error);
        });
    } else {
      mediaElement.pause();
      setIsPlayingKey(null);
    }
  };

  const openModal = (key) => {
    setSelectedItemKey(key);
  };

  const closeModal = () => {
    setSelectedItemKey(null);
  };

  const setMediaRef = useCallback((element, key) => {
    mediaRefs.current[key] = element;
    if (element && observer.current) {
      observer.current.observe(element);
    }
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const key = entry.target.dataset.key;
        if (entry.isIntersecting) {
          playMedia(key);
        } else {
          pauseMedia(key);
        }
      });
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [playMedia, pauseMedia]);

  const filteredGallery = useMemo(() => {
    if (!gallery) return { images: [], videoFiles: [] };

    const filteredImages = gallery.images.filter(
      (image) => image.category.toLowerCase() === selectedCategory.toLowerCase()
    );
    const filteredVideos = gallery.videoFiles.filter(
      (video) => video.category.toLowerCase() === selectedCategory.toLowerCase()
    );

    return { images: filteredImages, videoFiles: filteredVideos };
  }, [gallery, selectedCategory]);

  const shuffleArray = useMemo(() => {
    const videos = filteredGallery?.videoFiles?.map((item) => ({
      key: item._key,
      videoUrl: item.videoUrl,
    }));
    const images = filteredGallery?.images?.map((item) => ({
      key: item._key,
      imageUrl: item.imageUrl,
    }));
    const array = [...videos, ...images];
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }, [gallery]);

  const breakpointColumnsObj = useMemo(
    () => ({
      default: 4,
      1280: 3,
      1024: 2,
      767: 2,
    }),
    []
  );

  const ChangingCaseOfCategory = (selectedCategory) => {
    return selectedCategory
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="my-8 px-16 lg:px-8 md:px-1">
      <h1 className="py-8 flex items-center justify-center font-custom text-3xl text-violet-500 font-bold">
        {ChangingCaseOfCategory(selectedCategory)}
      </h1>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {shuffleArray.map((item) => (
          <div
            key={item.key}
            className="media-item rounded-lg"
            data-key={item.key}
          >
            {item.videoUrl ? (
              <div className="media-wrapper rounded-lg">
                <video
                  src={item.videoUrl}
                  ref={(el) => setMediaRef(el, item.key)}
                  autoPlay={false}
                  muted
                  loop
                  preload="auto"
                  className="gallery-media rounded-lg shadow-xl"
                  controls={false}
                ></video>
                <motion.div className={``}>
                  <button
                    className="play-pause-button bg-[rgba(0,0,0,0.5)]"
                    onClick={() => togglePlayPause(item.key)}
                  >
                    {isPlayingKey === item.key ? <FaPause /> : <FaPlay />}
                  </button>
                  <button
                    className="expand-button"
                    onClick={() => openModal(item.key)}
                  >
                    <FaExpand />
                  </button>

                  <button
                    className="expand-button"
                    onClick={() => openModal(item.key)}
                  >
                    <FaExpand />
                  </button>
                </motion.div>
              </div>
            ) : (
              <div className="media-wrapper">
                <img
                  src={item.imageUrl}
                  alt={`Gallery item ${item.key}`}
                  ref={(el) => setMediaRef(el, item.key)}
                  className="gallery-media shadow-xl"
                />
                <div>
                  <button
                    className="expand-button"
                    onClick={() => openModal(item.key)}
                  >
                    <FaExpand />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </Masonry>

      <AnimatePresence>
        {selectedItemKey && (
          <motion.div
            className="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="modal-content rounded-lg">
              <button className="modal-close" onClick={closeModal}>
                <RxCross1 />
              </button>
              {shuffleArray.find((item) => item.key === selectedItemKey)
                ?.videoUrl ? (
                <video
                  src={
                    shuffleArray.find((item) => item.key === selectedItemKey)
                      .videoUrl
                  }
                  autoPlay
                  controls
                  muted
                  className="modal-media rounded-lg"
                ></video>
              ) : (
                <img
                  src={
                    shuffleArray.find((item) => item.key === selectedItemKey)
                      .imageUrl
                  }
                  alt={`Gallery item ${selectedItemKey}`}
                  className="modal-media rounded-lg"
                />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default CategoryData;
