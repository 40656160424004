// utils/redux/store.js
import { configureStore } from '@reduxjs/toolkit'; 
import { galleryReducer, aboutReducer, bannerReducer, blogReducer, brandReducer } from './gallerySlice';

export const store = configureStore({
reducer:{
  gallery: galleryReducer,
  about: aboutReducer,
  blog: blogReducer,
  banner: bannerReducer,
  brands: brandReducer,
}  
})
