import imageUrlBuilder from '@sanity/image-url';
import myConfiguredSanityClient from '@sanity/client';




export const client = myConfiguredSanityClient({
  
  // projectId: process.env.SANITY_PROJECT_ID,
  projectId: "4zt9yoxc",
  dataset: 'production',
  apiVersion: '2021-10-21',
  useCdn: true,
  // token: process.env.SANITY_TOKEN,
  token: "skPpW7qsJcw2XhB3tBmys1ukZkGGuJe4kZHXFInGIcb9vVxjjR0y5oI55aRn2fQ92rPLuPTkIXr6Wvmh9ZuRAIQaqi7a2i0cVaJBYRVqQnDDB9xAo3Ja0uzhJSgmJUyHg5gdmJB21k6stRECb4I58VKbDku8Efa1HM0CMbiXfWMpupAFamut",
  
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source).url()
