// App.js
import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { store } from "./utils/redux/store";
import { Provider } from "react-redux";
import CategoryData from "./components/CategoryData";
import { SpinnerDotted } from "spinners-react";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Blog = lazy(() => import("./pages/Blog"));
const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));

const AppContent = () => {
  const [themeMode, setThemeMode] = useState(false);
  const handleData = (data) => {
    setThemeMode(data);
  };
  const isUnderMaintenance = false;

  return isUnderMaintenance ? (
    <div
      style={{
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        backgroundColor: "#2c3e50",
        color: "#ecf0f1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: "0",
        padding: "20px", // Ensure it fits well on small screens
        textAlign: "center", // Center-align text for better mobile readability
      }}
    >
      <div
        style={{
          padding: "40px",
          backgroundColor: "#34495e",
          borderRadius: "10px",
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
          maxWidth: "90%", // Constrain width on mobile
          width: "500px", // Ideal size for larger screens
        }}
      >
        <div style={{ fontSize: "50px", marginBottom: "20px" }}>🚧</div>
        <h1
          style={{
            fontSize: "42px",
            marginBottom: "20px",
            color: "#e74c3c",
          }}
        >
          Maintenance in Progress
        </h1>
        <p style={{ fontSize: "18px", marginBottom: "30px" }}>
          Our website is currently undergoing scheduled maintenance. We should
          be back shortly. Thank you for your patience.
        </p>
        <a
          href="https://www.shaileshattri.in"
          style={{
            textDecoration: "none",
            backgroundColor: "#e74c3c",
            color: "#ecf0f1",
            padding: "10px 20px",
            borderRadius: "5px",
            fontSize: "16px",
            transition: "background-color 0.3s",
            display: "inline-block",
          }}
        >
          Contact Support
        </a>
      </div>
    </div>
  ) : (
    <div
      className={`flex flex-col app ${
        !themeMode ? "dark-theme" : "light-theme"
      }`}
    >
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-screen w-full text-2xl font-semibold text-violet-500">
            <SpinnerDotted
              size={50}
              thickness={100}
              speed={100}
              color="#36ad47"
            />
          </div>
        }
      >
        <Header sendData={handleData} />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog themeMode={themeMode} />} />
            <Route path="/work/*" element={<CategoryData />} />
          </Routes>
        </div>
        <Footer />
      </Suspense>
    </div>
  );
};

const App = () => (
  <Provider store={store}>
    <Router>
      <AppContent />
    </Router>
  </Provider>
);

export default App;
